import { Box, darken, Grid, Typography } from "@mui/material"
import { baseURL } from "App";
import { People } from "components/shared/Body/People"
import { CentralSection } from "components/shared/Section/CentralSection"
import { useMemberQuery, useTeamQuery } from "generated/graphql";

export const Team = () => {

    const { data } = useMemberQuery();
    const { data: team } = useTeamQuery();

    return (<>
        <CentralSection bgcolor={"linear-gradient(-185deg, rgba(0,0,0,0.7) 20%, rgba(10, 10, 10, 1) 80%)"}>
            <Box width={'100%'} display={'flex'} sx={{
                m: 'auto',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: '2vw',
                minHeight: '100vh',
            }}>
                <People />
                <Grid container maxWidth={"xl"} textAlign="left" sx={{ my: 'auto', display: 'flex', justifyContent: 'space-between', width: '100%', m: 'auto', mb: '8vh', zIndex: 2 }}>
                    <Grid item lg={6} md={12} sx={{ my: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="h1" className={'dark-gradient'} lineHeight={1}>
                            {team?.team?.data?.attributes?.Landing?.Title}
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="subtitle1" lineHeight={1.2}> {team?.team?.data?.attributes?.Landing?.Subtitle} </Typography>
                    </Grid>
                </Grid>
            </Box>
        </CentralSection>
        <CentralSection bgcolor={"linear-gradient(-185deg, rgba(10, 10, 10, 1) 50%, rgba(0, 0, 0, 1) 100%)"}>
            <Grid container maxWidth={"xl"} textAlign="left" sx={{ my: 'auto', display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', mb: '8vh', zIndex: 2 }}>
                <Grid item md={12} xs={12} sx={{ my: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h2" className={'light-gradient'} lineHeight={1} mt={'3vh'} mb="8vh" textAlign="center">
                        {team?.team?.data?.attributes?.Team?.Title}
                    </Typography>
                </Grid>
                {data && data.members?.data.map(({ attributes }) => (
                    <Person
                        first={attributes?.Firstname as string}
                        last={attributes?.Lastname as string}
                        company={attributes?.Company as string}
                        role={attributes?.Role as string}
                        banner={baseURL + attributes?.Icon?.data?.attributes?.url}
                        image={baseURL + attributes?.Picture?.data?.attributes?.url}
                    />
                ))}
            </Grid>
        </CentralSection>
    </>)
}

export const Person = (props: {
    first?: string,
    last?: string,
    company?: string,
    role?: string,
    banner?: string,
    image?: string,
}) => {

    const getbg = () => {
        if (props.company) {
            if (props.company === "Daskell") return "#222";
            else if (props.company === "Voiseed") return darken("rgb(68, 77, 147)", 0.5);
            else if (props.company === "Revheal") return darken("rgb(117, 189, 172)", 0.5);
        }
        return "#222"
    }

    return (
        <Grid item lg={3} md={4} sm={6} sx={{ my: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box display={"flex"} flexDirection="column" position={"relative"} gap={1} p={"2vw"} textAlign="center" sx={{
            }}>
                <img alt={props.first} src={props.image} style={{
                    background: 'linear-gradient(-45deg, ' + getbg() + ' 20%, rgb(10, 10, 10) 80%)',
                    width: '80%',
                    maxWidth: '80%',
                    borderRadius: '16px',
                    margin: 'auto',
                    filter: "brightness(0.6)"
                }} />
                <img alt="Logo" src={props.banner} style={{
                    maxHeight: '48px',
                    position: 'absolute',
                    right: "12%",
                    bottom: "12%",
                }} />
                <Typography variant="body2"><b>{props.first} {props.last}</b> <br /> {props.role}</Typography>
            </Box>
        </Grid>
    )
}