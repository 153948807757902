import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { Logo } from "../Logo/Logo";

import { Link } from "react-router-dom";

export const Footer = () => {

    const theme = useTheme();

    return (
        <Box sx={{
            background: theme.palette.background.paper,
            borderTop: "1px solid #333",
            zIndex: 5,
            position: 'relative',
        }}>
            <Container maxWidth={'xl'} sx={{ my: 'auto' }}>
                <Grid container sx={{
                    width: '100%',
                    px: "2vw",
                    py: '2rem',
                    display: 'flex',
                    justifyContent: 'start',
                    maxWidth: 'none !important'
                }}>
                    <Grid item xs={12}>
                        <Logo />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} sx={{ my: '2vh' }}>
                        <Box>
                            <Typography variant="body2">Company</Typography>
                            <Link to="/" className="iubenda-white iubenda-embed link" title="Privacy Policy ">
                                <Typography variant="body1">
                                    Home
                                </Typography>
                            </Link>
                            <Link to="/labs" className="iubenda-white iubenda-embed link" title="Privacy Policy ">
                                <Typography variant="body1">
                                    Labs
                                </Typography>
                            </Link>
                            <Link to="/team" className="iubenda-white iubenda-embed link" title="Privacy Policy ">
                                <Typography variant="body1">
                                    Team
                                </Typography>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} sx={{ my: '2vh' }}>
                        <Box>
                            <Typography variant="body2">Products</Typography>
                            <a href="https://www.voiseed.com/" className="iubenda-white iubenda-embed link" title="Privacy Policy ">
                                <Typography variant="body1">
                                    Voiseed
                                </Typography>
                            </a>
                            <a href="https://revheal.ai" className="iubenda-white iubenda-embed link" title="Privacy Policy ">
                                <Typography variant="body1">
                                    Revheal
                                </Typography>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} sx={{ my: '2vh' }}>
                        <Box>
                            <Typography variant="body2">Where to find us</Typography>
                            <a href="https://www.linkedin.com/company/daskell/" className="iubenda-white iubenda-embed link" title="Privacy Policy ">
                                <Typography variant="body1">
                                    LinkedIn
                                </Typography>
                            </a>
                            <a href="https://pitchbook.com/profiles/company/492689-62" className="iubenda-white iubenda-embed link" title="Privacy Policy ">
                                <Typography variant="body1">
                                    PitchBook
                                </Typography>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} sx={{ my: '2vh' }}>
                        <Box>
                            <Typography variant="body2">Contacts</Typography>
                            <a color={theme.palette.background.paper} href={'mailto:info@daskell.com?subject=Introduction'}>
                                <Typography variant="body1">
                                    info@daskell.com
                                </Typography>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} sx={{ my: '2vh' }}>
                        <Box>
                            <Typography variant="body2">Offices</Typography>
                            <Typography variant="body1">
                                Milan, Italy
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} sx={{ my: '2vh' }}>
                        <Box>

                            <Typography variant="body2">Policies</Typography>
                            <a href="https://www.iubenda.com/privacy-policy/57682687" className="iubenda-white iubenda-embed link" title="Privacy Policy ">
                                <Typography variant="body1">Privacy Policy</Typography>
                            </a>
                            <script type="text/javascript" src="/js/privacy.js"></script>
                            <a href="https://www.iubenda.com/privacy-policy/57682687/cookie-policy" className="iubenda-white iubenda-embed link" title="Cookie Policy ">
                                <Typography variant="body1">Cookie Policy</Typography>
                            </a>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth={'xl'} sx={{ my: 'auto' }}>
                <Grid container sx={{
                    width: '100%',
                    display: 'flex',
                    p: "2vw",
                    borderTop: "1px solid #333",
                    justifyContent: 'space-between',
                    maxWidth: 'none !important'
                }}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" lineHeight={1.2}>
                            <b>Daskell S.r.L.</b>
                            <br />
                            Copyright © Daskell S.r.L. | Via Giotto 28, 20145, Milan, Italy | VAT, tax code, and number of registration with the Milan Company Register 10609260962 | REA number MI 2544663
                            <br />
                            Contributed capital €11.012,15 | Iscritta all'apposita sezione speciale in qualità di Start-Up innovativa | PEC: daskellsrl@pec.it
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}