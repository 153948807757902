import { Box, Typography } from "@mui/material"
import { ReactNode } from "react"
import { NavLink, NavLinkProps } from "react-router-dom"
import { NAVIGATION_HEIGHT } from "variables/variables"
import { Letter } from "../Logo/Letter"

export const NavigationLink = (props: NavLinkProps & { variant?: "body2" | "h1" | "h2" | "h3" | "h4" | "h5" , onClick?: () => void}) => {
    // route that it links to is currently selected.
    return (
        <Box onClick={props.onClick}>
            <NavLink {...props} className={({ isActive }) =>
                isActive ? "active-link" : "link"
            }>
                <Typography variant={props.variant ? props.variant : "body2"}>
                    {props.children as ReactNode}
                </Typography>
            </NavLink>
        </Box>
    )
}

export const DesktopNavigation = () => {
    return (
        <Box sx={{
            width: '100%',
            position: 'fixed',
            zIndex: 10,
            left: 0,
            height: NAVIGATION_HEIGHT,
            display: 'flex',
            px: "2vw",
            justifyContent: 'space-between',
            backdropFilter: "blur(8px)",
}}>
            <Box flex={1} m={'auto'}>
                <Letter />
            </Box>
            <Box flex={1} m={'auto'} display={'flex'} gap={2} justifyContent={'end'}>
                <NavigationLink to="/" end>About us</NavigationLink>
                <NavigationLink to="/labs" end>Labs</NavigationLink>
                <NavigationLink to="/team" end>Team</NavigationLink>
            </Box>
        </Box >
    )
}