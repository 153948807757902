import { Box } from "@mui/material"

export const Wave = () => {

    const style = {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        right: 0,
        // top: '5em'
    }
    return (
        <Box sx={style}>
            <svg id="wavesvg" height="50vh" width="80vw" viewBox="0 0 779 212" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path stroke="#fff" transform="translate(-26, -114)" fill="none" d="M29.8351008,206.740457 C44.873251,183.736651 57.265457,173.247645 67.0117188,175.273438 C90.2961375,180.113181 86.6737752,238.15625 102.332031,238.15625 C117.923775,238.15625 116.042109,173.146274 129.511719,175.273438 C144.932734,177.708774 144.909982,253.835181 160.113281,247.28125 C182.079904,237.811744 186.674668,137.615909 203.851562,143.226563 C225.092636,150.164737 216.462295,277.858507 236.949219,272.378906 C261.298233,265.866319 273.652776,112.074556 293.523437,119.242188 C317.402776,127.855806 300.559155,295.636902 324.449219,303.941406 C344.463452,310.89862 356.848416,167.137578 381.332031,149.765625 C396.776151,138.8075 388.088775,232.98755 404.304688,247.28125 C413.215728,255.135987 414.109806,202.619565 431.585938,194.0625 C440.69965,189.600034 451.853123,229.284914 457.484375,221.242188 C478.046482,191.874757 471.838301,106.394476 483.972656,119.242188 C504.264082,140.726507 507.002012,265.938075 522.335938,289.90625 C530.935605,303.348231 520.189931,215.510693 531.839844,194.0625 C536.324697,185.805615 544.348179,230.496094 554.605469,230.496094 C564.814975,230.496094 562.575487,196.706289 572.773438,194.0625 C580.423144,192.079336 584.431614,227.856436 590.300781,221.242188 C606.986302,202.438467 604.357901,127.951976 617.882813,143.226563 C648.805166,178.149242 652.468677,309.309563 679.195313,321.636719 C699.396411,330.954094 690.21412,220.823709 711.738281,186.515625 C718.803963,175.253397 723.532233,228.43425 736.375,230.496094 C747.036139,232.207688 745.049924,196.693346 758.746094,194.0625 C769.137815,192.066393 773.841699,221.242188 784.550781,221.242188 C791.570247,221.242188 797.127539,212.182292 801.222656,194.0625" id="waveline"></path>
            </svg>
        </Box>
    )
}