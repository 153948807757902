import { useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { routes } from "routes/routes"
import { Banner } from "./Banner/Banner"
import { Body } from "./Body/Body"
import { Loader } from "./Content/Content"
import { Footer } from "./Footer/Footer"
import { Header } from "./Header/Header"
import { Navigation } from "./Navigation/Navigation"

export const RouteLoader = (props: {
    load?: (ids?: string[]) => Promise<void>;
    children: JSX.Element
}) => {

    useEffect(() => {
        if (props.load) {
            props.load();//.then(() => null).catch(() => null);
        }
    });

    return (<>{props.children}</>)
}

export const Layout = () => {
    return (
        <Body>
            <BrowserRouter>
                <Header />
                <Navigation />
                <Routes>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<Loader path={route.path}
                                key={route.path}
                                page={route.page}>
                                <RouteLoader load={route.load}>
                                    {route.component}
                                </RouteLoader>
                            </Loader>}>
                        </Route>
                    ))}
                </Routes>
                <Banner />
                <Footer />
            </BrowserRouter>
        </Body>
    )
}