import { Box } from "@mui/material"
import { useState } from "react"
import { NAVIGATION_HEIGHT } from "variables/variables"
import { Letter } from "../Logo/Letter"
import { NavigationLink } from "./DesktopNavigation"

export const MobileNavigation = () => {

    const [isOpen, setOpen] = useState(false);

    const menu = {
        height: '30px',
        width: '30px',
        position: 'relative',
        zIndex: 99,
        cursor: 'pointer',
        display: 'block',
        marginLeft: 'auto',
        ".line": {
            height: '2px',
            display: ' block',
            width: ' 30px',
            backgroundColor: ' #fff',
            marginBottom: ' 7px',
            cursor: ' pointer',
            transition: 'transform .2s ease, background-color .5s ease',
            transform: isOpen ? 'translate(0px, 0px) rotate(-45deg)' : "",
            "&.left": {
                width: isOpen ? "15px" : '16.5px',
                transition: 'all 200ms linear',
                transform: isOpen ? 'translate(2px, 4px) rotate(45deg)' : "",
            },
            "&.right": {
                width: isOpen ? "15px" : '16.5px',
                float: ' right',
                transition: ' all 200ms linear',
                transform: isOpen ? 'translate(-3px, -3.5px) rotate(45deg)' : "",
            },
            ":hover": {
                "&.left": {
                    width: '30px',
                }
            },
        }
    }

    const menuContainer = {
        position: 'fixed',
        zIndex: 80,
        "&:before, &:after": {
            content: '""',
            position: "fixed",
            width: '100vw',
            height: '100vh',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "rgba(20, 21, 26, 0.6)",
            borderBottomLeftRadius: isOpen ? 0 : '200%',
            zIndex: -1,
            transition: 'transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s, border-radius linear 0.8s',
            transform: isOpen ? 'translateX(0%) translateY(0%)' : 'translateX(100%) translateY(-100%)',
        },
        "&:after": {
            background: 'rgba(9,9,12,1)',
            transitionDelay: isOpen ? '0.3s' : '0s',
        },
        "&:before": {
            transitionDelay: isOpen ? '0s' : '0.3s',
        },
        ".content": {
            p: '2rem',
            textAlign: 'right',
            visibility: isOpen ? 'visible' : 'hidden',
            transition: 'visibility 0s ease',
            transitionDelay: isOpen ? '1s' : 0,
            opacity: isOpen ? 1 : 0,
            display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh', width: '100vw', left: 0, right: 0, position: 'fixed'
        }
    }

    return (
        <Box sx={{
            width: '100%',
            position: 'fixed',
            zIndex: 10,
            left: 0,
            height: NAVIGATION_HEIGHT,
            display: 'flex',
            px: "1.5rem",
            justifyContent: 'space-between',
        }}>
            <Box flex={1} m={'auto'}>
                <Letter />
            </Box>
            <Box flex={1} m={'auto'}>
                <Box sx={menu} onClick={() => setOpen(!isOpen)}>
                    <Box className="line left"></Box>
                    <Box className="line"></Box>
                    <Box className="line right"></Box>
                </Box>
            </Box>
            <Box sx={menuContainer}>
                <Box className="content">
                    <Box>
                        <NavigationLink onClick={() => setOpen(false)} variant="h2" to="/" end>About us</NavigationLink>
                        <NavigationLink onClick={() => setOpen(false)} variant="h2" to="/labs" end>Labs</NavigationLink>
                        <NavigationLink onClick={() => setOpen(false)} variant="h2" to="/team" end>Team</NavigationLink>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}