import { Hub } from "pages/Hub";
import { Team } from "pages/Team";
import { Landing } from "../pages/Landing";
import { teamLoad } from "./loaders";

export interface RouteConfigProps {
    path: string;
    page: string;
    component: JSX.Element;
    load?: (ids?: string[]) => Promise<void>;
    layout?: (path: string) => void;
    icon?: JSX.Element;
}

export const routes: RouteConfigProps[] = [
    {
        path: "/",
        page: "Landing",
        component: <Landing/>,
        // load: async () => (),
        // layout: () => (),
    },
    {
        path: "/team",
        page: "Team",
        component: <Team/>,
        load: async() => teamLoad(),
        // load: async () => (),
        // layout: () => (),
    },
    {
        path: "/labs",
        page: "Labs",
        component: <Hub/>,
        // load: async () => (),
        // layout: () => (),
    },
]