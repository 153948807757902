import { Box } from "@mui/material"

export const Brain = () => {
    return (
        <Box style={{
            position: 'absolute',
            zIndex: 2,
            top: "10vh",
            right: 0,
            overflow: 'hidden',
            left: 0,
        }}>
            <svg id="spiral" width="100vw" height="80vh" viewBox="0 0 1109 1064" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="A4-Copy-7" transform="translate(-688.000000, -152.000000)" stroke="#979797">
                        <g id="Group" transform="translate(582.413581, 37.463840)">
                            <path d="M289.629897,271.897955 C360.99141,193.041513 521.891462,104.932823 665.04603,116.534526 C808.209364,128.13694 965.98414,179.143851 1040.52397,271.875714 C1093.06239,337.236561 1122.15722,426.783328 1153.53439,523.308263 C1166.69832,563.804186 1180.26177,605.52854 1196.11909,647.213934 C1222.41681,716.344847 1217.57686,790.875602 1190.07458,857.209519 C1161.53264,926.051026 1108.58289,986.067652 1040.67947,1022.06811 C1008.51525,1039.12066 978.561016,1056.97244 949.567212,1074.25205 C858.248188,1128.67601 776.470789,1177.43819 665.085983,1177.53802 C587.594205,1177.60551 500.543116,1161.10263 428.601967,1131.33131 C364.415943,1104.76928 312.24601,1067.65303 289.706944,1022.28739 C279.024984,1000.78721 265.798508,976.852998 251.596207,951.153395 C202.192803,861.756056 140.9537,750.979333 134.085331,647.003203 C125.246838,513.20253 218.260541,350.763064 289.629897,271.897955 Z" id="line-1"></path>
                            <path d="M289.629897,271.897955 C360.99141,193.041513 521.891462,104.932823 665.04603,116.534526 C808.209364,128.13694 965.98414,179.143851 1040.52397,271.875714 C1093.06239,337.236561 1122.15722,426.783328 1153.53439,523.308263 C1166.69832,563.804186 1180.26177,605.52854 1196.11909,647.213934 C1222.41681,716.344847 1217.57686,790.875602 1190.07458,857.209519 C1161.53264,926.051026 1108.58289,986.067652 1040.67947,1022.06811 C1008.51525,1039.12066 978.561016,1056.97244 949.567212,1074.25205 C858.248188,1128.67601 776.470789,1177.43819 665.085983,1177.53802 C587.594205,1177.60551 500.543116,1161.10263 428.601967,1131.33131 C364.415943,1104.76928 312.24601,1067.65303 289.706944,1022.28739 C279.024984,1000.78721 265.798508,976.852998 251.596207,951.153395 C202.192803,861.756056 140.9537,750.979333 134.085331,647.003203 C125.246838,513.20253 218.260541,350.763064 289.629897,271.897955 Z" id="line-1"></path>
                            <path d="M262.629897,271.897955 C333.99141,193.041513 494.891462,104.932823 638.04603,116.534526 C781.209364,128.13694 938.98414,179.143851 1013.52397,271.875714 C1066.06239,337.236561 1095.15722,426.783328 1126.53439,523.308263 C1139.69832,563.804186 1153.26177,605.52854 1169.11909,647.213934 C1195.41681,716.344847 1190.57686,790.875602 1163.07458,857.209519 C1134.53264,926.051026 1081.58289,986.067652 1013.67947,1022.06811 C981.515246,1039.12066 951.561016,1056.97244 922.567212,1074.25205 C831.248188,1128.67601 749.470789,1177.43819 638.085983,1177.53802 C560.594205,1177.60551 473.543116,1161.10263 401.601967,1131.33131 C337.415943,1104.76928 285.24601,1067.65303 262.706944,1022.28739 C252.024984,1000.78721 238.798508,976.852998 224.596207,951.153395 C175.192803,861.756056 113.9537,750.979333 107.085331,647.003203 C98.246838,513.20253 191.260541,350.763064 262.629897,271.897955 Z" id="line-2"></path>
                            <path d="M319.137648,327.288809 C379.992683,260.094164 517.203355,185.014474 639.281755,194.900469 C761.368913,204.787173 895.920468,248.246359 959.487593,327.266573 C1004.29426,382.965681 1029.10559,459.276376 1055.86509,541.532596 C1067.09339,576.047359 1078.6625,611.609106 1092.18816,647.137657 C1114.61149,706.038224 1110.48376,769.539414 1087.03341,826.056704 C1062.69549,884.71311 1017.54488,935.850332 959.643117,966.524526 C932.208102,981.058572 906.658122,996.273787 881.927367,1011.00136 C804.050471,1057.37835 734.311297,1098.93394 639.321678,1099.01901 C573.235669,1099.07652 498.997332,1085.01359 437.644963,1059.64378 C382.918149,1037.01371 338.432627,1005.39481 319.214756,966.743747 C310.103342,948.418834 298.821553,928.019346 286.707387,906.115203 C244.57465,829.933196 192.345279,735.53283 186.487699,646.926781 C178.950295,532.910509 258.274889,394.491984 319.137648,327.288809 Z" id="line-2" transform="translate(646.488612, 646.519008) rotate(-315.000000) translate(-646.488612, -646.519008) "></path>
                            <path d="M438.133538,372.890075 C502.585526,331.114903 598.808523,268.647845 664.962849,285.52065 C676.555593,288.477405 688.762449,291.323699 701.390578,294.26818 C772.775581,310.912914 857.653308,330.68166 921.066758,391.42458 C995.622077,462.84015 1045.11835,566.145952 1027.09841,646.927301 C1009.06127,727.785792 959.784977,849.026774 921.007295,902.716059 C888.115048,948.25672 792.030009,974.09178 708.226672,996.681748 C693.322664,1000.69926 678.8048,1004.61271 665.093378,1008.51187 C642.38968,1001.01136 618.61599,994.197699 594.923827,987.407548 C594.174711,987.192852 593.425675,986.978183 592.676754,986.76352 L588.184908,985.475438 C515.598595,964.64706 444.787201,943.473735 409.136701,902.679794 C360.738802,847.299336 317.971941,753.933885 303.081643,646.967222 C296.129997,597.029036 305.516396,546.184048 323.699355,502.347052 C344.426014,452.377488 376.581765,411.505518 409.022761,391.489025 C417.672545,386.152002 427.469146,379.802302 438.133538,372.890075 Z" id="line-3"></path>
                            <path d="M418.133538,372.890075 C482.585526,331.114903 578.808523,268.647845 644.962849,285.52065 C656.555593,288.477405 668.762449,291.323699 681.390578,294.26818 C752.775581,310.912914 837.653308,330.68166 901.066758,391.42458 C975.622077,462.84015 1025.11835,566.145952 1007.09841,646.927301 C989.061272,727.785792 939.784977,849.026774 901.007295,902.716059 C868.115048,948.25672 772.030009,974.09178 688.226672,996.681748 C673.322664,1000.69926 658.8048,1004.61271 645.093378,1008.51187 C622.38968,1001.01136 598.61599,994.197699 574.923827,987.407548 C574.174711,987.192852 573.425675,986.978183 572.676754,986.76352 L568.184908,985.475438 C495.598595,964.64706 424.787201,943.473735 389.136701,902.679794 C340.738802,847.299336 297.971941,753.933885 283.081643,646.967222 C276.129997,597.029036 285.516396,546.184048 303.699355,502.347052 C324.426014,452.377488 356.581765,411.505518 389.022761,391.489025 C397.672545,386.152002 407.469146,379.802302 418.133538,372.890075 Z" id="line-3"></path>
                            <path d="M468.34154,433.332424 C518.570307,400.818154 593.559796,352.176764 645.119657,365.310259 C654.159528,367.612925 663.678274,369.829573 673.525524,372.122681 C729.168603,385.080162 795.3317,400.465364 844.761799,447.75275 C902.863216,503.335567 941.449853,583.737853 927.405987,646.613743 C913.344886,709.566799 874.932033,803.960668 844.702283,845.761017 C819.065248,881.210762 744.166911,901.308744 678.852171,918.892233 C667.24271,922.017635 655.933724,925.062165 645.25364,928.096704 C627.558431,922.259258 609.030278,916.955811 590.565618,911.670682 L590.565618,911.670682 L587.063389,910.668093 C529.879693,894.292388 473.82857,877.807188 445.754999,845.724759 C408.028866,802.611399 374.694311,729.926115 363.087404,646.653711 C357.669793,607.785689 364.985727,568.212079 379.156154,534.092889 C395.307031,495.20522 420.361459,463.39481 445.641025,447.817124 C452.386104,443.660694 460.025475,438.715609 468.34154,433.332424 Z" id="line-4" transform="translate(645.797619, 645.619742) rotate(-45.000000) translate(-645.797619, -645.619742) "></path>
                            <path d="M586.761992,452.004321 C601.319507,446.544853 618.539716,447.182622 635.17846,448.513102 L635.17846,448.513102 L636.96635,448.658729 C638.850934,448.814801 640.726618,448.977499 642.588696,449.139016 C650.445637,449.820529 658.060881,450.480492 665.082456,450.536144 C707.159701,450.869645 775.508873,483.712653 804.417465,508.118179 C833.316533,532.515665 858.780033,562.875847 862.086804,647.055786 C865.393154,731.224998 849.529723,746.587109 804.411259,785.959468 C759.302603,825.323268 719.412262,853.212618 665.174167,843.54392 C610.899211,833.868651 561.457279,821.563232 525.785934,785.982195 C490.135889,750.422403 468.321391,722.74153 468.084075,647.034624 C467.851429,571.327361 502.965802,531.108902 525.787912,508.088142 C528.977984,504.870303 532.544052,500.378622 536.589903,495.280072 C548.347073,480.463777 564.182484,460.472255 586.761992,452.004321 Z" id="line-4"></path>
                            <path d="M567.761992,452.004321 C582.319507,446.544853 599.539716,447.182622 616.17846,448.513102 L616.17846,448.513102 L617.96635,448.658729 C619.850934,448.814801 621.726618,448.977499 623.588696,449.139016 C631.445637,449.820529 639.060881,450.480492 646.082456,450.536144 C688.159701,450.869645 756.508873,483.712653 785.417465,508.118179 C814.316533,532.515665 839.780033,562.875847 843.086804,647.055786 C846.393154,731.224998 830.529723,746.587109 785.411259,785.959468 C740.302603,825.323268 700.412262,853.212618 646.174167,843.54392 C591.899211,833.868651 542.457279,821.563232 506.785934,785.982195 C471.135889,750.422403 449.321391,722.74153 449.084075,647.034624 C448.851429,571.327361 483.965802,531.108902 506.787912,508.088142 C509.977984,504.870303 513.544052,500.378622 517.589903,495.280072 C529.347073,480.463777 545.182484,460.472255 567.761992,452.004321 Z" id="line-5"></path>
                            <path d="M599.893905,531.62865 C609.769229,527.925791 621.720031,528.981108 632.841114,529.945582 C637.496197,530.349293 642.008087,530.740001 646.168227,530.772968 C671.030008,530.969985 711.41205,550.376928 728.492278,564.794021 C745.562956,579.203052 760.593744,597.139021 762.546858,646.850291 C764.49955,696.550833 755.133201,705.623303 728.486071,728.872629 C701.848699,752.113441 678.295884,768.605939 646.259922,762.896096 C614.187102,757.179684 584.963628,749.91921 563.882664,728.895353 C542.823028,707.892766 529.936707,691.543603 529.796518,646.829125 C529.659086,602.11429 550.402958,578.360602 563.884641,564.763987 C565.776706,562.855792 567.892208,560.192577 570.291859,557.169093 C577.229496,548.42789 586.568547,536.625136 599.893905,531.62865 Z" id="line-5" transform="translate(646.296520, 646.532902) rotate(-300.000000) translate(-646.296520, -646.532902) "></path>
                        </g>
                    </g>
                </g>
            </svg>
        </Box>
    )
}