import { baseURL } from "App";
import { useBrandQuery } from "generated/graphql";

export const Letter = () => {

    const { data: brand } = useBrandQuery();

    const height = 48;
    return (
        <img alt="Daskell's Logo" height={height} src={baseURL + brand?.brand?.data?.attributes?.Letter?.data?.attributes?.url} style={{zIndex: 99, position: 'relative'}} />
    )
}