import Helmet from "react-helmet";

export const Header = () => {

    const defaultProps = {
        description: 'Daskell is a company specialized in Machine Learning, Artificial Intelligence and Deep Learning. Daskell\'s goal is to speed up the transition process towards a data-driven strategy for your company, allowing you to maintain a competitive position in step with innovation.',
        keywords: 'Data Science,Machine Learning,Artificial Intelligence, Deep Learning, Milano, Milan, Italia,Innovazione,Intelligenza Artificiale,Statistica,Internet of Things,IoT',
        siteUrl: 'https://daskell.com',
        previewImage: 'https://daskell.com/images/brand/logo.png',
        siteName: 'Daskell',
        pageTitle: 'Daskell | Data Science Company'
    }

    return (
        <Helmet
            encodeSpecialCharacters={true}
        >
            <meta charSet="utf-8" />
            {/* <base target="_blank" href="https://daskell.com/" /> */}
            <meta name="description" content={defaultProps.description} />
            <meta name="keywords" content={defaultProps.keywords} />
            <meta property="og:url" content={defaultProps.siteUrl} key="ogurl" />
            <meta property="og:image" content={defaultProps.previewImage} key="ogimage" />
            <meta property="og:site_name" content={defaultProps.siteName} key="ogsitename" />
            <meta property="og:title" content={defaultProps.pageTitle} key="ogtitle" />
            <meta property="og:description" content={defaultProps.description} key="ogdescription" />
            <title>{defaultProps.pageTitle}</title>
        </Helmet>
    )
}