import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import 'App.scss';
import { Cursor } from 'components/shared/Cursor/Cursor';
import { Layout } from 'components/shared/Layout';
import generateThemes from 'themes/defaults';

const { darkTheme } = generateThemes();

// export const baseURL = 'http://localhost:1337'
// export const baseURL = 'http://192.168.1.192:1337';
export const baseURL = '';

const client = new ApolloClient({
  uri: baseURL + '/graphql',
  cache: new InMemoryCache()
})

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Cursor />
        <Layout />
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
