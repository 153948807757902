import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import { Box, Divider, Grid, Link, Typography, useTheme } from "@mui/material";
import { baseURL } from 'App';
import { Circle } from "components/shared/Body/Circle";
import { Wave } from "components/shared/Body/Wave";
import { Press } from 'components/shared/Press/Press';
import { useAboutQuery, usePressesQuery, useProductQuery } from 'generated/graphql';
import { useState } from 'react';
import { MOBILE_THR } from 'variables/variables';
import { CentralSection } from "../components/shared/Section/CentralSection";
import { Brain } from "./Brain";

export const start1 = "transparent";
export const end1 = "#00005577";
export const start2 = end1;
export const end2 = "#000000EE";
export const start3 = end2;
export const end3 = "#000500EE";
export const start4 = end3;
export const end4 = "transparent";
export const start5 = end4;
export const end5 = "transparent";

export const MediaCard = (props: {
    company: string,
    website: string,
    image: string,
    title: string,
    description: string,
    reverse?: boolean,
}) => {

    const theme = useTheme();
    const isMobile = window.innerWidth <= MOBILE_THR;

    return (
        <Grid container sx={{
            bgcolor: theme.palette.background.paper,
            borderRadius: "8px",
            textAlign: "left",
            my: '4vh',
            display: 'flex',
            flexDirection: props.reverse ? 'row-reverse' : 'row',
            "img": {
                filter: 'brightness(0.5)',
                transition: "all 0.3s ease",
            },
            ":hover": {
                "img": {
                    filter: 'brightness(0.8)',
                }
            }
        }}>
            <Grid item lg={6} md={6} sm={12} sx={{
                p: '2rem !important',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <Typography variant="h4" sx={{
                    lineHeight: 1,
                }}>{props.title}</Typography>
                <br />
                <Link target={"_blank"} href={props.website} style={{
                    maxWidth: '200px',
                    borderBottom: "2px solid " + theme.palette.primary.main,
                    textDecoration: "none",
                    display: 'flex',
                    justifyContent: 'space-between'
                    // textDecorationStyle: "wavy"
                }}>
                    <Typography variant="body1">Website {props.company} </Typography>
                    <ArrowOutwardRoundedIcon />
                </Link>
                <br />
                <Typography variant="body1">{props.description}</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{
                p: '0 !important',
                m: 'auto !important',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <img src={props.image} alt={props.company} style={{
                    margin: 0,
                    right: 0,
                    borderRadius: '8px',
                    position: "relative",
                    maxWidth: '90%',
                    width: '90%',
                    maxHeight: '55vh',
                    transform: isMobile ? "" : "scale(1.05) " + ((props.reverse === true) ? " translateX(-5%)" : " translateX(5%)"),
                }} />
            </Grid>
        </Grid>
    )
}

export const Landing = () => {

    const theme = useTheme();

    const { data } = useProductQuery();
    const { data: about } = useAboutQuery();
    const { data: presses } = usePressesQuery();

    return (
        <>
            <CentralSection bgcolor={'linear-gradient(' + start1 + ',' + end1 + ')'}>
                <Box width={'100%'} display={'flex'} sx={{
                    m: 'auto',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: '2vw',
                    minHeight: '100vh',
                    position: 'relative'
                }}>
                    <Grid container maxWidth={"xl"} sx={{
                        m: 'auto',
                        zIndex: 2,
                    }}>
                        <Grid item md={10} sm={12} sx={{ m: 'auto' }}>
                            <Brain />
                            <Typography variant="h1" zIndex={2} className={'some-gradient'} position="relative" lineHeight={1.2}>
                                {about && about.about?.data?.attributes?.Landing?.Title}
                            </Typography>
                            <br />
                        </Grid>
                    </Grid>
                </Box>
            </CentralSection>
            <CentralSection bgcolor={'linear-gradient(' + start2 + ',' + end2 + ')'}>
                <Box width={'100%'} display={'flex'} sx={{
                    my: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    textAlign: 'center',
                    minHeight: '80vh',
                    p: '2vw',
                }}>
                    <Wave />
                    <Grid container maxWidth={"xl"} sx={{
                        m: 'auto',
                        zIndex: 2,
                    }}>
                        <Grid item lg={8} md={10} sm={12} sx={{ m: 'auto' }}>
                            <Typography variant="h2" className="" lineHeight={1.2}>
                                {about && about.about?.data?.attributes?.Following?.Title}
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="subtitle1" component={"p"}>
                                {about && about.about?.data?.attributes?.Following?.Subtitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </CentralSection>
            <CentralSection bgcolor={'linear-gradient(' + start3 + ',' + end3 + ')'}>
                <Box sx={{
                    my: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    p: '2vw',
                }}>
                    <Grid container maxWidth={"xl"} textAlign="center" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', m: 'auto', mb: '2vh' }}>
                        <Grid item xs={12} sx={{ my: 'auto' }}>
                            <Typography variant="h3" lineHeight={1.2}>
                                {about && about.about?.data?.attributes?.Products?.Title}
                            </Typography>
                        </Grid>
                    </Grid>
                    {data && data.products?.data.map(({ attributes }, index) => (
                        <MediaCard
                            company={attributes?.Company as string}
                            image={baseURL + attributes?.Banner?.data?.attributes?.url as string}
                            title={attributes?.Name as string}
                            description={attributes?.Description as string}
                            website={attributes?.WebsiteURL as string}
                            reverse={index % 2 !== 0}
                        />
                    ))}
                </Box>
            </CentralSection>
            <CentralSection bgcolor={'linear-gradient(' + start4 + ',' + end4 + ')'}>
                <Box sx={{
                    my: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: 1,
                    position: 'relative',
                    textAlign: 'left',
                    minHeight: '80vh',
                    p: '2vw',
                }}>
                    <Circle />
                    <Grid container maxWidth={"xl"} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', m: 'auto', zIndex: 3 }}>
                        <Grid item xl={10} lg={9} md={10} sm={12} sx={{ m: 'auto' }}>
                            <Typography variant="h2" lineHeight={1.2} className="light-gradient">
                                {about && about.about?.data?.attributes?.Labs?.Title}
                            </Typography>
                            <br />
                            <Typography variant="subtitle1" lineHeight={1.2} className="light-gradient">
                                {about && about.about?.data?.attributes?.Labs?.Subtitle}
                            </Typography>
                            <br />
                            <Link href={about?.about?.data?.attributes?.Labs?.URL as string} style={{
                                borderBottom: "2px solid " + theme.palette.secondary.main,
                                textDecoration: "none",
                            }}>
                                <Typography variant="overline" sx={{ color: theme.palette.secondary.main }}>
                                    {about?.about?.data?.attributes?.Labs?.Call as string}
                                </Typography>
                                <ArrowOutwardRoundedIcon fontSize="small" sx={{ color: theme.palette.secondary.main }} />
                            </Link>
                        </Grid>
                    </Grid>
                </Box>

            </CentralSection>
            <CentralSection bgcolor={'linear-gradient(' + start5 + ',' + end5 + ')'}>
                <Box pb={'3rem'}>
                    <Typography variant="h2">News</Typography>
                    <Divider />
                    <Box className="container x-scroll x-mandatory" sx={{
                        width: "100%",
                        height: "450px",
                        maxHeight: '80vh',
                        display: 'flex',
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: "10px",
                        marginBottom: "10px",
                        overflow: "scroll",
                        position: "relative",
                        scrollSnapType: "x mandatory",
                    }}>
                        {presses?.presses?.data.map(({ attributes }, index) => (
                            <Box sx={{
                                width: '350px',
                                maxWidth: '100%',
                                height: '100%',
                                mx: '8px',
                                scrollSnapAlign: "start",
                                scrollSnapStop: "normal",
                                color: "white",
                                fontSize: "50px",
                                justifyContent: "center",
                                alignItems: "center",
                                float: "left",
                            }}>
                                <Press
                                    title={attributes?.Title as string}
                                    subtitle={attributes?.Subtitle as string}
                                    extended={attributes?.Extended as string}
                                    url={attributes?.URL as string}
                                    source={attributes?.Source?.data?.attributes?.url as string}
                                    thumbnail={attributes?.Thumbnail?.data?.attributes?.url as string}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </CentralSection>
        </>
    )
}