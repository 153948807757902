import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import { textTransform } from "@mui/system";
import { baseURL } from "App";
import { Images } from "components/shared/Body/Images";
import { CentralSection } from "components/shared/Section/CentralSection";
import { useLabQuery, useResearchTopicsQuery } from "generated/graphql";

export const start1 = "rgba(89, 106, 155, .8)";
export const end1 = "#11111aaa";
export const start2 = end1;
export const end2 = "#11111a80";
export const start3 = end2;
export const end3 = "rgb(80, 80, 255)";
export const start4 = end3;
export const end4 = "rgb(185, 185, 125)";
export const start5 = end4;
export const end5 = "transparent";

export const Phase = (props: {
    title: string,
    description: string
}) => {
    return (
        <Box sx={{
            p: '1.5rem',
            m: '8px',
            height: '100%',
            border: '1px solid #fff6',
            borderRadius: '8px',
        }}>
            <Typography variant="h4" className="light-gradient">{props.title}</Typography>
            <br />
            <Divider />
            <br />
            <Typography variant="body1" className="">{props.description}</Typography>
        </Box>
    )
}

export const Competence = (props: {
    title: string
}) => {
    return (
        <Box sx={{
            p: '20px',
            borderRadius: '16px',
            background: '#4851b0',
            boxShadow: '20px 20px 60px #3d4596, -20px -20px 60px #535dca',
            m: '8px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            "*" : {
                fontWeight: '600',
            }
        }}>
            <Typography variant="subtitle1"><b>{props.title}</b></Typography>
        </Box>
    )
}

export const Topic = (props: {
    image: string,
    title: string,
    subtitle: string,
}) => {

    const theme = useTheme();

    return (
        <Grid item xs={6} sm={4} md={4} lg={3} xl={3} sx={{ my: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', p: '1px !important' }}>
            <Box sx={{
                textAlign: 'center',
                position: 'relative',
            }}>
                <img alt={"Banner for " + props.title} style={{
                    zIndex: 1,
                    maxWidth: '100%',
                    right: 0,
                    borderRadius: '16px'
                }} src={props.image} />
                <Box sx={{
                    position: 'absolute',
                    my: 'auto',
                    textAlign: 'left',
                    bottom: 0,
                    borderBottomRightRadius: '16px',
                    borderBottomLeftRadius: '16px',
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    p: '2vw',
                    zIndex: 2,
                    backdropFilter: 'blur(10px)',
                    width: '100%',
                }}>
                    <Typography variant="h6"><b>{props.title}</b></Typography>
                    <Typography variant="body1" color={theme.palette.secondary.main}> <b>{props.subtitle}</b></Typography>
                </Box>
            </Box>
        </Grid>
    )
}

export const Hub = () => {

    const { data: labs } = useLabQuery();
    const { data: researchTopics } = useResearchTopicsQuery();

    const theme = useTheme();

    return (<>
        <CentralSection bgcolor={"linear-gradient(" + start1 + ", " + end1 + ")"}>
            <Box width={'100%'} display={'flex'} sx={{
                m: 'auto',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                px: '2vw',
                py: '4rem',
                minHeight: '100vh',
            }}>
                <Images />
                <Grid container maxWidth={"xl"} columnSpacing={2} rowSpacing={2} textAlign="center" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mx: 'auto', zIndex: 2 }}>
                    <Grid item lg={8} md={10} xs={12} sx={{ m: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography variant="h1" className={'light-gradient'} lineHeight={1}>
                            {labs?.lab?.data?.attributes?.Landing?.Title}
                        </Typography>
                    </Grid>
                    <Grid item lg={8} md={10} xs={12} sx={{ m: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <Typography variant="h4" lineHeight={1.2}>
                            {labs?.lab?.data?.attributes?.Landing?.Subtitle}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </CentralSection>
        <CentralSection bgcolor={"linear-gradient(" + start2 + ", " + end2 + ")"}>
            <Box width={'100%'} display={'flex'} sx={{
                m: 'auto',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: '2vw',
                py: '4rem',
                minHeight: '80vh',
            }}>
                <Grid container maxWidth={"xl"} columnSpacing={2} rowSpacing={2} textAlign="center" sx={{ display: 'flex', justifyContent: 'start', width: '100%', mx: 'auto', zIndex: 2 }}>
                    <Grid item lg={6} md={10} xs={12} sx={{ mx: 'auto', mb: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                        <Typography variant="h2" lineHeight={1.2}>
                            {labs?.lab?.data?.attributes?.Process?.Title}
                        </Typography>
                        <br />
                    </Grid>
                    <Grid item lg={6} md={10} xs={12} sx={{ mx: 'auto', mb: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                        <Typography variant="caption" lineHeight={1.2}>
                            {labs?.lab?.data?.attributes?.Process?.Subtitle}
                        </Typography>
                        <br />
                        <Typography variant="caption" lineHeight={1.2}>
                            {labs?.lab?.data?.attributes?.Landing?.Subtitle2}
                        </Typography>
                        <br/>
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ mx: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'start', textAlign: 'left' }}>
                        <Phase
                            title={"01. " + labs?.lab?.data?.attributes?.Landing?.H1 as string}
                            description={labs?.lab?.data?.attributes?.Landing?.P1 as string}
                        />
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ mx: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'start', textAlign: 'left' }}>
                        <Phase
                            title={"02. " + labs?.lab?.data?.attributes?.Landing?.H2 as string}
                            description={labs?.lab?.data?.attributes?.Landing?.P2 as string}
                        />
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ mx: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'start', textAlign: 'left' }}>
                        <Phase
                            title={"03. " + labs?.lab?.data?.attributes?.Landing?.H3 as string}
                            description={labs?.lab?.data?.attributes?.Landing?.P3 as string}
                        />
                    </Grid>
                </Grid>
            </Box>
        </CentralSection>
        <CentralSection bgcolor={"linear-gradient(" + start3 + ", " + end3 + ")"}>
            <Box width={'100%'} display={'flex'} sx={{
                m: 'auto',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: '2vw',
                minHeight: '80vh',
            }}>
                <Grid container maxWidth={"xl"} columnSpacing={2} rowSpacing={2} textAlign="center" sx={{ display: 'flex', justifyContent: 'center', width: '100%', mx: 'auto', zIndex: 2 }}>
                    <Grid item xs={12} sx={{ mb: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                        <Typography variant="h2" className="dark-gradient" lineHeight={1.2}>
                            {labs?.lab?.data?.attributes?.Landing?.ServicesTitle}
                        </Typography>
                        <br />
                        <Typography variant="subtitle1">
                            {labs?.lab?.data?.attributes?.Landing?.ServicesDesc}
                        </Typography>
                    </Grid>
                    {labs?.lab?.data?.attributes?.Service?.map((service) => (
                        <Grid item md={3} xs={10} sx={{ mx: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                            <Competence title={service?.Title as string} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </CentralSection>
        {/* <CentralSection bgcolor={"linear-gradient(" + start4 + ", " + end4 + ")"}>
            <Box width={'100%'} display={'flex'} sx={{
                m: 'auto',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: '2vw',
                minHeight: '80vh',
            }}>
                <Grid container maxWidth={"xl"} columnSpacing={2} rowSpacing={2} textAlign="center" sx={{ display: 'flex', justifyContent: 'center', width: '100%', mx: 'auto', zIndex: 2 }}>
                    <Grid item xs={12} sx={{ my: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                        <Typography variant="h2" className="light-gradient" lineHeight={1.2}>
                            {labs?.lab?.data?.attributes?.Research?.Title}
                        </Typography>
                        <br />
                    </Grid>
                    {researchTopics?.researchTopics?.data.map(({ attributes }) => (
                        <Topic
                            image={baseURL + attributes?.Image?.data?.attributes?.url}
                            title={attributes?.Title as string}
                            subtitle={attributes?.Subtitle as string}
                        />
                    ))}
                </Grid>
            </Box>
        </CentralSection> */}
    </>)
}