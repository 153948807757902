import { useEffect } from "react";
import { RouteProps, useLocation } from "react-router-dom";

export const BasePath = () => {
    const uselocation = useLocation();
    return uselocation.pathname.substring(0, uselocation.pathname.lastIndexOf("/"));
};

export const GetPathID = (level?: number) => {
    const uselocation = useLocation();
    if (level) {
        const pathnames: string[] = uselocation.pathname.split("/");
        const paths: string[] = [];
        for (let step = 0; step < level; step++) {
            paths.push(pathnames[pathnames.length - (step + 1) * 2]);
        }
        return paths;
    } else {
        const pathnames: string[] = uselocation.pathname.split("/");
        return [pathnames[pathnames.length - 2]];
    }
};

export function Loader({
    children,
    load,
    layout,
}: { children: JSX.Element } & RouteProps & {
    path?: string;
    page?: string;
    load?: (id: string[]) => Promise<void>;
    layout?: (path: string) => void;
}) {
    useEffect(() => {
    }, []) // }, [window.location.hash])
    return children;
}