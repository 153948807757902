// import { useQuery, gql } from "@apollo/client";

export const teamLoad = async () => {

    // const MEMBERS = gql`
    //     {
    //         members {
    //             data {
    //               attributes {
    //                 Firstname
    //                 Lastname
    //               }
    //             }
    //           }
    //     }
    // `

    // const { loading, error, data } = useQuery(MEMBERS);

    // console.log(data);

}

