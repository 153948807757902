import { Box } from "@mui/material";
import { baseURL } from "App";
import { useTeamQuery } from "generated/graphql";

export const People = () => {
    const style = {
        position: 'absolute',
        right: 0,
        top: "20vh",    
        zIndex: 1,
        opacity: 0.7,
        borderRadius: '10px',
    }

    const { data: team } = useTeamQuery();

    return (
        <Box sx={style}>
            <img style={{
                borderRadius: '16px',
                aspectRatio: 'auto',
                maxWidth: '100vw',
                maxHeight: '60vh'
            }} alt="Team" src={baseURL + team?.team?.data?.attributes?.Picture?.data?.attributes?.url} />
        </Box>
    )
}