import { Box, Container, Grid, Link, Typography, useTheme } from "@mui/material";
import { useBannerQuery } from "generated/graphql";

export const Banner = () => {

    const theme = useTheme();

    const { data: banner } = useBannerQuery();

    return (
        <Box sx={{
            bgcolor: theme.palette.background.default,
            py: '3rem !important',
        }}>
            <Container maxWidth={'xl'} sx={{ my: 'auto' }}>
                <Grid container sx={{
                    width: '100%',
                    display: 'flex',
                    maxWidth: 'none !important',
                }}>
                    <Grid item xs={12} md={8} xl={8} textAlign="left" sx={{
                        px: '2vw !important',
                        mb: '2vh',
                    }}>
                        <Typography variant="h4">
                            {banner?.banner?.data?.attributes?.Content?.Title}
                        </Typography>
                        <Typography variant="h6" color={theme.palette.secondary.main}>
                            {banner?.banner?.data?.attributes?.Content?.Subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4} sx={{ m: 'auto', p: '2vw !important', bgcolor: theme.palette.background.paper, display: 'flex', flexDirection: 'column', justifyContent: 'center' }} textAlign="left">
                        <Typography variant="h6">
                            <b>Mail:</b> <Link href={banner?.banner?.data?.attributes?.Email?.Label as string}>
                                {banner?.banner?.data?.attributes?.Email?.Email}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>

    )
}