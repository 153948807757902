import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MOBILE_THR } from "variables/variables";
import { DesktopNavigation } from "./DesktopNavigation";
import { MobileNavigation } from "./MobileNavigation";

export const Navigation = () => {

    const location = useLocation();
    
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isMobile = width <= MOBILE_THR;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [location.pathname]);

    return (isMobile ? <MobileNavigation /> : <DesktopNavigation />)
}