import { Box } from "@mui/material"

export const Images = () => {

    const style = {
        position: 'absolute',
        zIndex: 1,
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
    }

    return (
        <Box sx={style}>

            <svg id="imagesvg" width="100vw" height="100vh" viewBox="0 0 441 322" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="A4-Copy" transform="translate(-56.000000, -130.000000)" stroke="#979797">
                        <g id="Group" transform="translate(57.400504, 130.941919)">
                            <line x1="20.8023982" y1="35.0580813" x2="421.573848" y2="35.0580813" id="matrix" strokeWidth="30" strokeLinejoin="round" strokeDasharray="23,15"></line>
                            <line x1="24.8023982" y1="77.0580813" x2="425.599496" y2="77.0580813" id="matrix" strokeWidth="30" strokeLinejoin="round" strokeDasharray="22,15"></line>
                            <line x1="24.8023982" y1="161.058081" x2="425.599496" y2="161.058081" id="matrix" strokeWidth="30" strokeLinejoin="round" strokeDasharray="22,15"></line>
                            <line x1="24.8023982" y1="245.058081" x2="425.599496" y2="245.058081" id="matrix" strokeWidth="30" strokeLinejoin="round" strokeDasharray="22,15"></line>
                            <line x1="20.8023982" y1="119.058081" x2="421.573848" y2="119.058081" id="matrix" strokeWidth="30" strokeLinejoin="round" strokeDasharray="23,15"></line>
                            <line x1="20.8023982" y1="203.058081" x2="421.573848" y2="203.058081" id="matrix" strokeWidth="30" strokeLinejoin="round" strokeDasharray="23,15"></line>
                            <line x1="20.8023982" y1="287.058081" x2="421.573848" y2="287.058081" id="matrix" strokeWidth="30" strokeLinejoin="round" strokeDasharray="23,15"></line>
                            <polygon id="images" points="332 1.42108547e-14 332 119.058081 438.729068 119.058081 438.729068 1.42108547e-14"></polygon>
                            <polygon id="images" points="0 159.058081 0 320.058081 144 320.058081 144 159.058081"></polygon>
                            <polygon id="images" points="225 159.058081 225 279.058081 335 279.058081 335 159.058081"></polygon>
                        </g>
                    </g>
                </g>
            </svg>
        </Box>
    )
}