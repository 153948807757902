import { Box } from "@mui/material"

export const Circle = () => {

    const style = {
        position: 'absolute',
        margin: 'auto',
        zIndex: 2,
        right: 0,
        top: '15vh'
    }

    return (
        <Box sx={style}>
            <svg id="circle" width="60vw" height="50vh" viewBox="0 0 613 623" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="A4-Copy-5" transform="translate(-781.000000, -316.000000)" fill="#979797" fillRule="nonzero">
                    <path id="line" d="M1125.48625,317.061651 C1125.97642,317.992442 1125.65959,319.133832 1124.78404,319.685029 L1124.64855,319.763182 L1111.099,326.898 L1123.45617,327.240698 C1207.29143,331.930206 1276.56293,368.274061 1324.95967,428.737848 C1369.46161,484.335721 1393.66812,557.654649 1393.33149,632.443156 C1392.97015,711.908674 1367.59165,786.916966 1319.8569,842.033546 C1266.34614,903.819349 1187.67069,938.147637 1087.61447,938.147637 C987.42466,938.147637 908.615991,904.028367 855.120226,842.55001 C807.191178,787.46903 781.900897,712.38419 781.900897,632.434062 C781.900897,552.938366 807.790727,477.640717 856.323115,422.312125 C910.179825,360.913623 988.850913,326.720486 1087.61447,326.720486 C1088.71904,326.720486 1089.61447,327.615916 1089.61447,328.720486 C1089.61447,329.825055 1088.71904,330.720486 1087.61447,330.720486 C989.981043,330.720486 912.412151,364.434571 859.330191,424.949831 C811.466825,479.515715 785.900897,553.871332 785.900897,632.434062 C785.900897,711.461601 810.875699,785.609808 858.137765,839.92428 C910.855139,900.508096 988.555732,934.147637 1087.61447,934.147637 C1186.53413,934.147637 1264.0992,900.303838 1316.83325,839.414857 C1363.9093,785.058843 1388.97435,710.976982 1389.33153,632.425059 C1389.66414,558.529475 1365.75204,486.102268 1321.83685,431.237438 C1274.62076,372.248687 1207.2847,336.578114 1125.7638,331.384846 L1123.28914,331.236811 L1110.989,330.896 L1124.12248,338.769601 C1125.02248,339.309159 1125.34506,340.444778 1124.88812,341.369237 L1124.80946,341.513332 C1124.2699,342.413328 1123.13428,342.73591 1122.20982,342.278966 L1122.06573,342.20031 L1102.38465,330.401255 L1099.33513,328.57303 L1102.48111,326.916288 L1122.78472,316.223956 C1123.76205,315.709272 1124.97157,316.084321 1125.48625,317.061651 Z"></path>
                </g>
            </svg>
        </Box>
    )
}