import { Box, Container } from "@mui/material"
// import { Parallax } from 'react-scroll-parallax';

export const CentralSection = (props: { children: React.ReactElement, filter?: number, bgcolor?: string }) => {
    return (
        <Box sx={{
            background: props.bgcolor ? props.bgcolor : 'transparent',
            minHeight: "45vh",
            display: 'flex',
            flexDirection: 'column',
            py: '3vh',
            zIndex: 2,
        }}>
            <Container maxWidth={'xl'} sx={{my: 'auto' }}>  
            {/* filter: 'blur(' + props.filter + 'px)' */}
            {/* <Parallax speed={20}> */}
                {props.children}
            {/* </Parallax> */}
            </Container>
        </Box>
    )
}