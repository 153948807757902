import { baseURL } from "App";
import { useBrandQuery } from "generated/graphql";

export const Logo = () => {

    const { data: brand } = useBrandQuery();

    const height = 30;
    return (
        <img alt="Daskell's Logo" height={height} src={baseURL + brand?.brand?.data?.attributes?.Logo?.data?.attributes?.url}/>
    )
}