import type { Theme } from "@mui/material";
import { createTheme } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
// import type {} from "@mui/x-data-grid/themeAugmentation";

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const generateThemes = () => {
  // Create a base theme to extend

  const primaryMain = "#6495ED";
  const primaryContrast = "#fff";
  const secondaryLight = "rgb(136, 138, 145)";
  const secondaryMain = "rgb(145, 235, 168)";
  const secondaryDark = "rgb(36, 38, 45)";
  const backgroundDark = "#000E";
  const backgroundMain = "#000D";

  const commonThemeOptions: DeepPartial<Theme> = {
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: ["Satoshi"].join(
        ","
      ),
      h1: {
        fontFamily: ["Excon"],
        fontWeight: 300,
        color: primaryContrast,
      },
      h2: {
        fontFamily: ["Satoshi"],
        fontWeight: 400,
        color: primaryContrast,
      },
      h3: {
        fontFamily: ["Satoshi"],
        fontWeight: 400,
        color: primaryContrast,
      },
      h4: {
        fontFamily: ["Satoshi"],
        fontWeight: 400,
        color: primaryContrast,
      },
      overline: {
        fontSize: "0.6rem",
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: "64px !important",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {},
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
          focusRipple: false,
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {},
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: "none !important",
            borderRadius: "5px !important",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontWeight: "bolder",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          },
        },
      },
      MuiAvatarGroup: {
        styleOverrides: {
          avatar: {
            width: "30px",
            height: "30px",
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          container: {
            maxWidth: '2000px !important',
          },
          item: {
            padding: "0 !important",
          }
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            maxWidth: '2000px !important',
          }
        }
      }
    },
  };

  const darkTheme = createTheme(
    {
      palette: {
        mode: "dark",
        primary: {
          main: primaryMain,
          contrastText: primaryContrast,
        },
        secondary: {
          main: secondaryMain,
          dark: secondaryDark,
          light: secondaryLight,
        },
        background: {
          paper: backgroundDark,
          default: backgroundMain,
        },
      },
      components: {
        MuiLink: {
          styleOverrides: {
            root: {
              color: primaryContrast
            }
          }
        }
      }
    },
    commonThemeOptions
  );

  const fonting = (dim: Variant, sizes: string[], fontWeight: number, fontFamily: string, 
    textTransform?: "none" | "capitalize" | "uppercase" | "lowercase" | "full-width" | "full-size-kana",
    color?: string,
    letterSpacing?: string,
    ) => {
    darkTheme.typography[dim] = {
      fontSize: sizes[0],
      fontWeight,
      fontFamily,
      textTransform,
      color,
      letterSpacing,
      [darkTheme.breakpoints.down('xl')]: {
        fontSize: sizes[1],
        fontWeight,
      },
      [darkTheme.breakpoints.down('lg')]: {
        fontSize: sizes[2],
        fontWeight
      },
      [darkTheme.breakpoints.down('md')]: {
        fontSize: sizes[3],
        fontWeight
      },
      [darkTheme.breakpoints.down('sm')]: {
        fontSize: sizes[4],
        fontWeight
      },
    }
  }

  fonting("h1", ["9rem", "8rem", "6rem", "4rem", "3rem"], 400, "GeneralSans", "none", "white", "-0.0625em")
  fonting("h2", ["7rem", "6rem", "4rem", "4rem", "2.5rem"], 400, "GeneralSans", "none", "white", "-0.0625em")
  fonting("h3", ["6rem", "5rem", "4rem", "3.5rem", "2.2rem"], 350, "GeneralSans", "none", "white", "-0.0625em")
  fonting("h4", ["4rem", "3rem", "2.5rem", "2rem", "1.8rem"], 400, "GeneralSans", "none", "white", "-0.0625em")
  fonting("h5", ["2.5rem", "2rem", "1.8rem", "1.5rem", "1.3rem"], 400, "GeneralSans", "none", "white", "-0.0625em")
  fonting("h6", ["1.5rem", "1.5rem", "1.3rem", "1.1rem", "1rem"], 450, "GeneralSans", "none", "white", "-0.0325em")
  fonting("subtitle1", ["2.5rem", "2rem", "2rem", "1.5rem", "1.2rem"], 300, "GeneralSans", "none", "white", "-0.0325em")
  fonting("caption", ["2rem", "1.8rem", "1.5rem", "1.3rem", "1.1rem"], 350, "GeneralSans", "none", "white", "-0.0325em")
  fonting("subtitle2", ["12px", "12px", "12px", "12px", "12px"], 200, "GeneralSans", "none", "white", "-0.0125em")
  fonting("overline", ["1.4rem", "1.2rem", "1.1rem", "1rem", "0.9rem"], 700, "Excon", "uppercase", darkTheme.palette.primary.main)
  fonting("body1", ["1.4rem", "1.2rem", "1.1rem", "1rem", "0.9rem"], 400, "GeneralSans", "none", "white")
  fonting("body2", ["1.4rem", "1.2rem", "1.1rem", "1rem", "0.9rem"], 200, "GeneralSans", "none", "white")

  return { darkTheme };
};

export default generateThemes;
