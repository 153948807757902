import { Card, CardContent, CardMedia, Link, Typography, useTheme } from "@mui/material";
import { baseURL } from "App";

export const Press = (props: {
    title?: string,
    subtitle?: string,
    extended?: string,
    url?: string,
    source?: string,
    thumbnail?: string,
}) => {

    const theme = useTheme();

    return (
        <Link target={"_blank"} href={props.url}>
            <Card
                sx={{
                    width: '500px',
                    maxWidth: '100%',
                    height: '100%',
                    borderRadius: '0px',
                    position: 'relative',
                    bgcolor: 'rgb(220, 220, 220)',
                    transition: 'all 0.3s ease',
                    ":hover": {
                        transform: "translateY(-1%)",
                        filter: 'brightness(130%)',
                    }
                }}
            >

                <img
                    alt={props.title}
                    src={baseURL + props.source}
                    style={{
                        position: 'absolute',
                        height: '10%',
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        top: '3.5%',
                        // filter: 'contrast(0) brightness(0.1)',
                        zIndex: 2,
                    }}
                />
                <CardMedia
                    component="img"
                    height="40%"
                    sx={{
                        filter: 'brightness(0.6)',
                        marginTop: '20%',
                    }}
                    image={baseURL + props.thumbnail}
                    alt={props.title}
                />
                <CardContent>
                    <Typography variant="h6" color={theme.palette.background.paper} >{props.title}</Typography>
                </CardContent>
            </Card>
        </Link>
    )
}